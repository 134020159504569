import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; // Firebase Firestore import
import HeaderModal from "./HeaderModal";

// Images
import nongdamLogo from "../images/linklogo.png";

const Header = ({ currentPage }) => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [headerNav, setHeaderNav] = useState(currentPage);

  // Modal state
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        await fetchUserInfo(user.uid);
      } else {
        setIsLoggedIn(false);
        navigate("/login");
      }
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, [auth, navigate]);

  const fetchUserInfo = async (uid) => {
    try {
      const userDoc = doc(db, "users", uid); // Assuming 'users' collection exists
      const userSnap = await getDoc(userDoc);

      if (userSnap.exists()) {
        setUserInfo(userSnap.data());
      } else {
        console.error("No user data found!");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsLoggedIn(false);
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const toggleModal = () => {
    setOpen(!isOpen);
  };

  return (
    <>
      <Wrap>
        <Nav>
          <NavLeft>
            <Logo
              onClick={() => {
                navigate("/");
                setHeaderNav(headerNav);
              }}
              src={nongdamLogo}
              alt="농담 로고"
            />
            {isLoggedIn && (
              <CategoryWrap>
                <FormCheckText
                  id="main"
                  onClick={() => navigate("/")}
                  currentPage={headerNav}
                >
                  홈
                </FormCheckText>
                {/* <FormCheckText
                  id="marketPrice"
                  onClick={() => navigate("/marketprice")}
                  currentPage={headerNav}
                >
                  시세
                </FormCheckText>
                <FormCheckText
                  id="schedule"
                  onClick={() => navigate("/schedule")}
                  currentPage={headerNav}
                >
                  일정
                </FormCheckText>
                <FormCheckText
                  id="accountbook"
                  onClick={() => navigate("/accountbook")}
                  currentPage={headerNav}
                >
                  장부
                </FormCheckText>
                <FormCheckText
                  id="workLog"
                  onClick={() => navigate("/worklog")}
                  currentPage={headerNav}
                >
                  농장일지
                </FormCheckText>
                <FormCheckText
                  id="analysis"
                  onClick={() => navigate("/analysis")}
                  currentPage={headerNav}
                >
                  농장 관리 현황
                </FormCheckText> */}
              </CategoryWrap>
            )}
          </NavLeft>
          <ProfileWrap>
            {isLoggedIn && userInfo && (
              <UserProfile
                profileImage={userInfo.profileImage || ""}
                onClick={toggleModal}
              />
            )}
            {isLoggedIn && (
              <LogoutButton onClick={handleLogout}>로그아웃</LogoutButton>
            )}
          </ProfileWrap>
        </Nav>
        {isOpen && (
          <HeaderModal
            isOpen={isOpen}
            toggleModal={toggleModal}
            userInfo={userInfo}
          />
        )}
      </Wrap>
    </>
  );
};

// Styled components
const Wrap = styled.div`
  width: 100vw;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.08);
  z-index: 1000;
  display: grid;
  grid-template-columns: 1fr repeat(3, 25.5%) 1fr;
  position: fixed;
  top: 0;
  left: 0;

  @media only screen and (max-width: 1220px) {
    grid-template-columns: 1fr repeat(3, 28%) 1fr;
  }
  @media only screen and (max-width: ${({ theme }) => theme.device.tablet}) {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
  }
`;

const Nav = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-column: 2 / 5;
  grid-row: 1 / 2;
`;

const NavLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Logo = styled.img`
  width: 90px;
  margin-right: 40px;
  cursor: pointer;

  @media only screen and (max-width: ${({ theme }) => theme.device.tablet}) {
    width: 70px;
    height: auto;
  }
`;

const UserProfile = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100px;
  cursor: pointer;
  background-image: url(${(props) => props.profileImage});
  background-position: center 30%;
  background-size: cover;

  @media only screen and (max-width: ${({ theme }) => theme.device.tablet}) {
    width: 40px;
    height: 40px;
  }
`;

const LogoutButton = styled.button`
  margin-left: 20px;
  padding: 8px 16px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: #fff;
  background-color: #55a349;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #449636;
  }
`;

const ProfileWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CategoryWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const FormCheckText = styled.span`
  margin-right: 60px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${(props) => (props.currentPage === props.id ? "#333" : "#666")};
  font-weight: ${(props) => (props.currentPage === props.id ? 600 : 400)};

  &:hover {
    font-weight: 600;
    color: #333;
  }

  @media only screen and (max-width: ${({ theme }) => theme.device.tablet}) {
    display: none;
  }
`;

export default Header;
