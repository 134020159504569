import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getAuth, updateProfile } from "firebase/auth";
import { db, storage } from "../../firebase"; // Add storage import
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const EditMemberInfo = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [nickname, setNickname] = useState("");
  const [address, setAddress] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");

  // Fetch user info
  useEffect(() => {
    const fetchUserInfo = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserInfo(data);
          setNickname(data.name || "");
          setAddress(data.address || "");
          setPreviewImage(data.profileImage || "");
        }
      }
    };
    fetchUserInfo();
  }, []);

  // Handle profile image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  // Upload image to Firebase Storage and get URL
  const uploadImage = async () => {
    if (profileImage) {
      const auth = getAuth();
      const user = auth.currentUser;
      const storageRef = ref(storage, `profileImages/${user.uid}`);
      const uploadTask = uploadBytesResumable(storageRef, profileImage);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          null,
          (error) => reject(error),
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          }
        );
      });
    }
    return previewImage; // Return current image URL if no new image is uploaded
  };

  // Update user info
  const updateUserInfo = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const imageURL = await uploadImage();
      const userRef = doc(db, "users", user.uid);
      const updatedData = { name: nickname, address, profileImage: imageURL };

      // Update Firestore
      await updateDoc(userRef, updatedData);

      // Update Firebase Auth profile
      await updateProfile(user, { displayName: nickname, photoURL: imageURL });

      // Update local state and localStorage
      const updatedUserInfo = { ...userInfo, ...updatedData };
      setUserInfo(updatedUserInfo);
      localStorage.setItem("userInfo", JSON.stringify(updatedUserInfo));

      // Redirect to MyPage
      navigate("/mypage");
    }
  };

  return (
    <Wrap>
      <Title>회원정보 수정</Title>
      <EditInfoWrap>
        <ProfileImageWrap>
          <ProfileImg
            style={{ backgroundImage: `url(${previewImage || ""})` }}
          />
          <InputLabel htmlFor="profileImage">이미지 변경</InputLabel>
          <ImageInput
            id="profileImage"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </ProfileImageWrap>
        <EachBoxWrap>
          <SmallTitle>닉네임</SmallTitle>
          <InputBox
            type="text"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            placeholder="닉네임을 입력하세요"
          />
        </EachBoxWrap>
        <EachBoxWrap>
          <SmallTitle>주소</SmallTitle>
          <InputBox
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="주소를 입력하세요"
          />
        </EachBoxWrap>
        <SubmitBtn
          onClick={updateUserInfo}
          disabled={!nickname || !address}
        >
          수정 완료
        </SubmitBtn>
      </EditInfoWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  border: none;
  width: 92%;
  height: auto;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  background: #ffffff;
  padding: 40px 40px 40px 40px;
  grid-column: 3 / 6;
  @media only screen and (max-width: 1550px) {
    margin-left: 10px;
  }
  @media only screen and (max-width: 760px) {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    margin-left: -20px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
    width: 100%;
    padding: 20px;
    grid-column: 2 / 3;
    grid-row: 3 / 5;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

const EditInfoWrap = styled.div`
  margin-top: 70px;
  @media only screen and (max-width: 760px) {
    margin-top: 30px;
  }
`;

const ProfileImageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const ProfileImg = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-bottom: 10px;
`;

const InputLabel = styled.label`
  cursor: pointer;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.mainColor};
  &:hover {
    text-decoration: underline;
  }
`;

const ImageInput = styled.input`
  display: none;
`;

const EachBoxWrap = styled.div`
  margin-bottom: 20px;
`;

const SmallTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

const InputBox = styled.input`
  border: 1px solid #999999;
  border-radius: 8px;
  font-size: ${({ theme }) => theme.fontSizes.md};
  padding: 4px;
  width: 300px;
  height: 28px;
  margin-top: 5px;
  :focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.colors.mainColor};
  }
`;

export const SubmitBtn = styled.button`
  margin-top: 20px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: white;
  background-color: ${({ theme }) => theme.colors.mainColor};
  border: 1px solid ${({ theme }) => theme.colors.mainColor};
  padding: 6px 12px;
  border-radius: 8px;

  cursor: pointer;

  &:hover {
    background: #22631c;
    border: 1px solid #22631c;
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.mainColor};
    border: 1px solid ${({ theme }) => theme.colors.mainColor};
    padding: 6px 12px;
    opacity: 0.3;
    cursor: default;
  }
  @media only screen and (max-width: 760px) {
    align-self: flex-start;
    margin-left: 0px;
  }
`;

export default EditMemberInfo;
