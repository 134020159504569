import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase"; // Import Firebase auth
import "./App.css";
import RouteChangeTracker from "./RouteChangeTracker";

// Components
import Main from "./pages/Main";
import Analysis from "./pages/Analysis";
import AccountBook from "./pages/AccountBook";
import MarketPrice from "./pages/MarketPrice";
import AccountWrite from "./components/accountbook/AccountWrite";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import MyPage from "./pages/MyPage";
import KakaoCallback from "./shared/Kakaocallback";
import OauthFilter from "./pages/OauthFilter";
import Schedule from "./pages/Schedule";
import theme from "./theme";
import WorkLog from "./pages/WorkLog";
import WirteWorkLog from "./pages/WriteWorkLog";
import DetailWorkLog from "./pages/DetailWorkLog";
import NotFound from "./components/NotFound";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(
    () => JSON.parse(localStorage.getItem("isLoggedIn")) || false
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        localStorage.setItem("isLoggedIn", true); // Persist login status
        if (location.pathname === "/login" || location.pathname === "/signup") {
          navigate("/"); // Redirect only if on login/signup page
        }
      } else {
        setIsLoggedIn(false);
        localStorage.setItem("isLoggedIn", false); // Persist logout status
        if (location.pathname !== "/login" && location.pathname !== "/signup") {
          navigate("/login"); // Redirect only if not on login/signup page
        }
      }
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, [navigate, location.pathname]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Routes>
          {isLoggedIn ? (
            <>
              <Route path="/" element={<Main />} />
              {/* <Route path="/analysis" element={<Analysis />} /> */}
              <Route path="/accountbook" element={<AccountBook />} />
              <Route path="/accountwrite" element={<AccountWrite />} />
              <Route path="/marketprice" element={<MarketPrice />} />
              <Route path="/callback/kakaotalk" element={<KakaoCallback />} />
              <Route path="/mypage/*" element={<MyPage />} />
              <Route path="/worklog/" element={<WorkLog />} />
              <Route path="/worklog/detail/:id" element={<DetailWorkLog />} />
              <Route path="/WriteWorkLog" element={<WirteWorkLog />} />
              <Route path="/schedule" element={<Schedule />} />
              <Route path="/code/auth" element={<OauthFilter />} />
              <Route path="/*" element={<NotFound />} />
            </>
          ) : (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/*" element={<Login />} /> {/* Redirect to login */}
            </>
          )}
        </Routes>
      </ThemeProvider>
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  html, body {
    font-family: "Noto Sans KR", sans-serif;
    color:  #02113B;
    margin: 0;
    padding: 0;
    font-size: 85%;
    background: ${({ theme }) => theme.colors.BackgroundColor};
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default App;
