import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from "../firebase";
import { KAKAO_AUTH_URL } from "../shared/Kakao";
import { FcGoogle } from "react-icons/fc";

const Login = () => {
  const navigate = useNavigate();
  const restkey = "c4933f2e281528b16504fc5192ff4712";
  if (!window.Kakao.isInitialized()) {
      window.Kakao.init(restkey);
      console.log(window.Kakao.isInitialized());
  }

  const onLoginWithKakao = () => {
    const redirectUri = `${window.location.origin}/callback/kakaotalk`;
  
      const scope = [
        "profile_nickname",
        "profile_image",
        "account_email",
      ].join(",");
  
      window.Kakao.Auth.authorize({
        redirectUri,
        scope,
      });
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Save user info to Firestore
      await saveUserData(user);

      console.log("Google login successful:", user);
      navigate("/"); // Redirect to homepage on success
    } catch (error) {
      console.error("Google login failed:", error);
    }
  };

  const saveUserData = async (user) => {
    try {
      const userRef = doc(db, "users", user.uid); // Save user data with UID as document ID
      await setDoc(userRef, {
        name: user.displayName || "Unknown User",
        email: user.email || "No Email Provided",
        profileImage: user.photoURL || "",
        lastLogin: new Date().toISOString(),
      });
      console.log("User data saved successfully to Firestore.");
    } catch (error) {
      console.error("Error saving user data:", error);
    }
  };

  return (
    <Container>
      <TopWrap>
        <Logo
          onClick={() => navigate("/")}
          src={require("../images/linklogo.png")}
          alt="Logo"
        />
        <p className="slogan">모두를 잇는 소셜 허브, 링크!</p>
        <p className="desc">
          함께하는 즐거움을 담은 링크
          <br />
          내 이야기를 나누고 모두와 이어지는 공간.
        </p>
      </TopWrap>
      <KakaoBtn onClick={onLoginWithKakao}>
        <img
          src={require("../images/kakao_login_large_wide.png")}
          alt="Kakao Login"
        />
      </KakaoBtn>
      <SubmitBtns>
      <SocialBtn
          bgColor="#A0A0A0"
          onClick={handleGoogleLogin}
        >
          <IconWrapper>
            <FcGoogle size={20} />
          </IconWrapper>
          <span>구글 로그인</span>
        </SocialBtn>
      </SubmitBtns>
    </Container>
  );
};

// Styled components
const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const TopWrap = styled.div`
  .slogan {
    font-weight: 700;
    font-size: 20px;
  }
  .desc {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 28px;
    color: #666666;
  }
`;

const Logo = styled.img`
  width: 130px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const SubmitBtns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
  }
`;
const SocialBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 40px;
  margin: 10px 0;
  padding: 0 15px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => props.bgColor || "#fff"};
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #fff;

  &:hover {
    background-color: #999;
  }

  span {
    margin-left: 10px;
  }
`;

const KakaoBtn = styled.button`
  width: 240px;
  height: 40px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  background-color: #fee500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }

  &:hover {
    opacity: 0.9;
  }
`;



export default Login;
