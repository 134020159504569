import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import axios from "axios";

const KAKAO_JAVASCRIPT_ID = "49230594ba99145380c340c468505185";
const CLIENT_ID = "c4933f2e281528b16504fc5192ff4712";
const REDIRECT_URI = "http://www.localhost:3000/callback/kakaotalk";

const KAKAO_OAUTH_SERVER_URL = "https://kauth.kakao.com";

export const KAKAO_AUTH_URL =
  KAKAO_OAUTH_SERVER_URL +
  `/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`;
const kakaoAuth = axios.create({
  baseURL: KAKAO_OAUTH_SERVER_URL,
  headers: {
    "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
  },
});

const Kakao = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");

    if (code) {
      handleKakaoLogin(code);
    } else {
      console.error("Authorization code not found");
      navigate("/"); // Redirect to the login page on failure
    }
  }, []);

  const handleKakaoLogin = async (code) => {
    try {
      // Exchange authorization code for access token
      const tokenResponse = await axios.post("https://kauth.kakao.com/oauth/token", null, {
        params: {
          grant_type: "authorization_code",
          client_id: "c4933f2e281528b16504fc5192ff4712", // Your Kakao REST API Key
          redirect_uri: `${window.location.origin}/callback/kakaotalk`,
          code,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const accessToken = tokenResponse.data.access_token;

      // Fetch Kakao user info
      const userInfoResponse = await axios.get("https://kapi.kakao.com/v2/user/me", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const kakaoUser = userInfoResponse.data;
      const email = kakaoUser.kakao_account.email || `${kakaoUser.id}@kakao.com`; // Fallback email
      const password = kakaoUser.id.toString(); // Use Kakao ID as password

      // Create or log in user in Firebase
      try {
        // Try to log in the user
        await signInWithEmailAndPassword(auth, email, password);
        console.log("Firebase login successful!");
      } catch (error) {
        if (error.code === "auth/user-not-found") {
          // User doesn't exist, create a new one
          await createUserWithEmailAndPassword(auth, email, password);
          console.log("Firebase user created successfully!");
        } else {
          throw error; // Rethrow other errors
        }
      }

      navigate("/"); // Redirect to the homepage after successful login
    } catch (error) {
      console.error("Kakao login failed:", error);
      navigate("/"); // Redirect to login page on failure
    }
  };

  return <div>Processing Kakao Login...</div>;
};

export default Kakao;
