import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import * as fabric from "fabric";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase";

const Weather = () => {
  const [decorations, setDecorations] = useState([]);
  const [background, setBackground] = useState("snowy");
  const [selectedTree, setSelectedTree] = useState("classic");
  const [selectedDecorationId, setSelectedDecorationId] = useState(null);
  const canvasRef = useRef(null);

  const treeShapes = {
    classic: "🎄",
    pine: "🌲",
    lush: "🌳",
  };

  const backgrounds = {
    snowy: "#f0f8ff",
    festive: "#ffefd5",
    cozy: "#f5f5dc",
  };

  // Load user's tree data from Firestore
  const loadUserTree = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.log("User is not logged in.");
      return;
    }

    try {
      const userDoc = doc(db, "users", user.uid);
      const docSnap = await getDoc(userDoc);

      if (docSnap.exists()) {
        const data = docSnap.data().christmasTree || {};
        setBackground(data.background || "snowy");
        setSelectedTree(data.selectedTree || "classic");
        setDecorations(data.decorations || []);
      }
    } catch (error) {
      console.error("Error loading user tree:", error.message);
    }
  };

  useEffect(() => {
    loadUserTree();
  }, []);

  useEffect(() => {
    const canvas = new fabric.Canvas("treeCanvas", {
      width: 700,
      height: 300,
      backgroundColor: backgrounds[background],
    });

    canvasRef.current = canvas;

    canvas.on("object:modified", (e) => {
      const modifiedObject = e.target;
      if (modifiedObject.id) {
        setDecorations((prev) =>
          prev.map((deco) =>
            deco.id === modifiedObject.id
              ? {
                  ...deco,
                  x: modifiedObject.left,
                  y: modifiedObject.top,
                  scaleX: modifiedObject.scaleX,
                  scaleY: modifiedObject.scaleY,
                  angle: modifiedObject.angle,
                }
              : deco
          )
        );
      }
    });

    canvas.on("selection:created", (e) => {
      if (e.selected && e.selected[0]) {
        setSelectedDecorationId(e.selected[0].id);
      }
    });

    canvas.on("selection:cleared", () => {
      setSelectedDecorationId(null);
    });

    return () => {
      canvas.dispose();
    };
  }, [background]);

  useEffect(() => {
    const canvas = canvasRef.current;

    canvas.clear();

    const treeText = new fabric.Text(treeShapes[selectedTree], {
      fontSize: 150,
      top: canvas.height / 2 - 75,
      left: canvas.width / 2 - 75,
      fill: "#228B22",
      selectable: false,
    });
    canvas.add(treeText);

    decorations.forEach((deco) => {
      const decoText = new fabric.Text(deco.emoji, {
        id: deco.id,
        fontSize: 40,
        left: deco.x,
        top: deco.y,
        scaleX: deco.scaleX || 1,
        scaleY: deco.scaleY || 1,
        angle: deco.angle || 0,
        selectable: true,
      });
      canvas.add(decoText);
    });

    canvas.renderAll();
  }, [decorations, selectedTree]);

  const addDecoration = (emoji) => {
    const canvas = canvasRef.current;
    const newDecoration = {
      id: Date.now(),
      emoji,
      x: 350,
      y: 150,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
    };

    const decoText = new fabric.Text(emoji, {
      id: newDecoration.id,
      fontSize: 40,
      left: newDecoration.x,
      top: newDecoration.y,
      scaleX: newDecoration.scaleX,
      scaleY: newDecoration.scaleY,
      angle: newDecoration.angle,
      selectable: true,
    });

    canvas.add(decoText);
    canvas.renderAll();
    setDecorations((prev) => [...prev, newDecoration]);
  };

  const removeSelectedDecoration = () => {
    if (!selectedDecorationId) return;

    const canvas = canvasRef.current;

    const target = canvas.getObjects().find((obj) => obj.id === selectedDecorationId);
    if (target) {
      canvas.remove(target);
    }

    setDecorations((prev) =>
      prev.filter((deco) => deco.id !== selectedDecorationId)
    );
    setSelectedDecorationId(null);
  };

  // Save tree data to Firestore
  const saveTreeToFirebase = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      alert("You need to be logged in to save your tree.");
      return;
    }

    const treeData = {
      decorations,
      background,
      selectedTree,
    };

    try {
      const userDoc = doc(db, "users", user.uid);
      await setDoc(userDoc, { christmasTree: treeData }, { merge: true });
      alert("Your Christmas tree has been saved!");
    } catch (error) {
      console.error("Error saving tree to Firestore:", error.message);
      alert("Failed to save your tree. Please try again later.");
    }
  };

  return (
    <Wrap>
      <Title>🎄 내 크리스마스 트리</Title>
      <Margin />
      <TreeCanvasContainer id="treeCanvasContainer">
        <canvas id="treeCanvas"></canvas>
      </TreeCanvasContainer>
      <OptionsWrap>
        <label>
          나무 종류:
          <TreeSelector>
            {Object.keys(treeShapes).map((shape) => (
              <TreeOption
                key={shape}
                onClick={() => setSelectedTree(shape)}
                selected={shape === selectedTree}
              >
                {treeShapes[shape]}
              </TreeOption>
            ))}
          </TreeSelector>
        </label>
        <label>
          배경:
          <select
            value={background}
            onChange={(e) => setBackground(e.target.value)}
          >
            {Object.keys(backgrounds).map((bg) => (
              <option key={bg} value={bg}>
                {bg.charAt(0).toUpperCase() + bg.slice(1)}
              </option>
            ))}
          </select>
        </label>
        <label>
          장식 추가하기:
          <input
            type="text"
            placeholder="Enter emoji (e.g., 🎁)"
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value) {
                addDecoration(e.target.value);
                e.target.value = "";
              }
            }}
          />
        </label>
        <RemoveButton
          onClick={removeSelectedDecoration}
          disabled={!selectedDecorationId}
        >
          장식 제거하기
        </RemoveButton>
      </OptionsWrap>
      <SaveButton onClick={saveTreeToFirebase}>트리 저장하기</SaveButton>
    </Wrap>
  );
};


// Styled Components (same as before, with minor updates for button state)
const RemoveButton = styled.button`
  padding: 5px 10px;
  background-color: ${(props) => (props.disabled ? "#ccc" : "#ff4d4d")};
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${(props) => (props.disabled ? "#ccc" : "#e60000")};
  }
`;

const boxFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(5%);
 
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Wrap = styled.div`
  border: none;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  grid-column: 2 / 4;
  grid-row: 2 / 8;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media only screen and (max-width: ${({ theme }) => theme.device.tablet}) {
    grid-column: 2 / 3;
    grid-row: 2 / 7;
    padding: 10px;
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: 10px;
`;

const Margin = styled.div`
  height: 10px;
  width: 100%;
  border-bottom: 0.5px solid #dddddd;
  margin-left: -20px;
  padding-right: 40px;
`;

const TreeCanvasContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 300px;
  width: 700px;
  max-width: 100%;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
`;

const OptionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: bold;
    color: #555;
  }

  select,
  input {
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
`;

const TreeSelector = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const TreeOption = styled.div`
  cursor: pointer;
  padding: 10px;
  border: 2px solid ${(props) => (props.selected ? "#4caf50" : "#ddd")};
  border-radius: 5px;
  font-size: 20px;
  background-color: ${(props) => (props.selected ? "#e8f5e9" : "#fff")};
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover {
    background-color: #f1f8e9;
  }
`;

const SaveButton = styled.button`
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;



export default Weather;
