import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useSelector } from "react-redux";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  ShimmerTitle,
  ShimmerText,
  ShimmerThumbnail,
} from "react-shimmer-effects";

const LettersAndMessages = () => {
  const userInfo = useSelector((state) => state.users?.user);
  const [tab, setTab] = useState("received"); // "received" or "sent"
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [sentMessages, setSentMessages] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [messageText, setMessageText] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (!userInfo?.uid) return;

      try {
        const receivedRef = doc(db, "christmasTreeMessages", userInfo.uid);
        const sentRef = doc(db, "sentMessages", userInfo.uid);

        const receivedSnap = await getDoc(receivedRef);
        const sentSnap = await getDoc(sentRef);

        setReceivedMessages(receivedSnap.exists() ? receivedSnap.data().messages || [] : []);
        setSentMessages(sentSnap.exists() ? sentSnap.data().messages || [] : []);
      } catch (error) {
        console.error("Error fetching messages:", error);
      } finally {
        setIsLoaded(true);
      }
    };

    fetchMessages();
  }, [userInfo]);

  const handleSendMessage = async (recipientId) => {
    if (!messageText) {
      alert("Please enter a message.");
      return;
    }

    const newMessage = {
      text: messageText,
      sender: userInfo.uid,
      recipient: recipientId,
      timestamp: new Date().toISOString(),
    };

    try {
      // Save to recipient's received messages
      const recipientRef = doc(db, "christmasTreeMessages", recipientId);
      const recipientSnap = await getDoc(recipientRef);
      const recipientMessages = recipientSnap.exists() ? recipientSnap.data().messages || [] : [];
      await setDoc(recipientRef, { messages: [...recipientMessages, newMessage] }, { merge: true });

      // Save to sender's sent messages
      const senderRef = doc(db, "sentMessages", userInfo.uid);
      const senderSnap = await getDoc(senderRef);
      const senderMessages = senderSnap.exists() ? senderSnap.data().messages || [] : [];
      await setDoc(senderRef, { messages: [...senderMessages, newMessage] }, { merge: true });

      setSentMessages((prev) => [...prev, newMessage]);
      alert("Message sent successfully!");
      setMessageText("");
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send the message. Please try again.");
    }
  };



  return (
    <Wrap>
      <Title>💌 내 편지함</Title>
      <Margin />

      {/* Tab Panel */}
      <TabWrap>
        <TabButton active={tab === "received"} onClick={() => setTab("received")}>
          받은 편지
        </TabButton>
        <TabButton active={tab === "sent"} onClick={() => setTab("sent")}>
          보낸 편지
        </TabButton>
      </TabWrap>

      {/* Tab Content */}
      <BoxWrap>
        {isLoaded ? (
          tab === "received" ? (
            receivedMessages.length > 0 ? (
              receivedMessages.map((message, idx) => (
                <MessageWrap key={idx}>
                  <MessageContent>{message.text}</MessageContent>
                  <MessageSender>- {message.sender || "Anonymous"}</MessageSender>
                </MessageWrap>
              ))
            ) : (
              <EmptyMessage>No received messages.</EmptyMessage>
            )
          ) : (
            sentMessages.length > 0 ? (
              sentMessages.map((message, idx) => (
                <MessageWrap key={idx}>
                  <MessageContent>{message.text}</MessageContent>
                  <MessageSender>To: {message.recipient}</MessageSender>
                </MessageWrap>
              ))
            ) : (
              <EmptyMessage>No sent messages.</EmptyMessage>
            )
          )
        ) : (
          <LoadingWrap>
            <ShimmerTitle line={1} gap={10} variant="secondary" />
            <ShimmerText line={2} gap={10} />
          </LoadingWrap>
        )}
      </BoxWrap>

     
    </Wrap>
  );
};

// Styled Components
const boxFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Wrap = styled.div`
  border: none;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  grid-column: 4 / 5;
  grid-row: 2 / 5;
  background-color: #fff;
  @media only screen and (max-width: ${({ theme }) => theme.device.tablet}) {
    grid-column: 2 / 3;
    grid-row: 7 / 10;
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: 10px;
`;

const Margin = styled.div`
  height: 10px;
  width: 100%;
  border-bottom: 0.5px solid #dddddd;
  margin-left: -20px;
  padding-right: 40px;
`;

const TabWrap = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 10px;
  margin-bottom: 20px;
`;

const TabButton = styled.button`
  flex: 1;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => (props.active ? "#fff" : "#333")};
  background-color: ${(props) => (props.active ? "#4caf50" : "#f9f9f9")};
  border: ${(props) => (props.active ? "none" : "1px solid #ddd")};
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.active ? "#45a049" : "#f1f1f1")};
  }
`;

const BoxWrap = styled.div`
  height: 290px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const MessageWrap = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  animation: ${boxFade} 1s;
`;

const MessageContent = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
`;

const MessageSender = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #666;
`;

const EmptyMessage = styled.div`
  text-align: center;
  font-size: 14px;
  color: #999;
  margin-top: 50px;
`;

const LoadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;


export default LettersAndMessages;
