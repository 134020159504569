import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, OAuthProvider, signInWithCredential } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

const Kakaocallback = () => {
  const navigate = useNavigate();
  const [idToken, setIdToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  const auth = getAuth();
  const provider = new OAuthProvider("oidc.kakao");
  const restkey = "c4933f2e281528b16504fc5192ff4712";

  const getKakaoToken = async (code) => {
    try {
      const response = await fetch("https://kauth.kakao.com/oauth/token", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          grant_type: "authorization_code",
          client_id: restkey,
          redirect_uri: `${window.location.origin}/callback/kakaotalk`,
          code: code,
        }).toString(),
      });

      const data = await response.json();

      if (data.error) {
        console.error("Kakao token error:", data.error);
        navigate("/");
        return;
      }

      // Handle ID Token
      if (data.id_token) {
        setIdToken(data.id_token);
        const credential = provider.credential({ idToken: data.id_token });

        signInWithCredential(auth, credential)
          .then((result) => {
            const user = result.user;
            const credential = OAuthProvider.credentialFromResult(result);
            console.log("Firebase Sign-In Successful:", user);

            // Save user data to Firestore
            saveUserToFirestore(user.uid, {
              name: user.displayName || "Unknown",
              email: user.email || "No Email Provided",
              profileImage: user.photoURL || "",
              lastLogin: new Date().toISOString(),
            });
          })
          .catch((error) => {
            console.error("Error during Firebase Sign-In:", error);
          });
      } else {
        navigate("/");
        return;
      }

      // Handle Access Token
      if (data.access_token) {
        setAccessToken(data.access_token);
        if (window.Kakao) {
          window.Kakao.init(restkey);
          window.Kakao.Auth.setAccessToken(data.access_token);
        } else {
          console.error("Kakao SDK not initialized.");
        }
      }
    } catch (error) {
      console.error("Error fetching Kakao token:", error);
      navigate("/");
    }
  };

  const saveUserToFirestore = async (uid, userData) => {
    try {
      const userRef = doc(db, "users", uid);
      await setDoc(userRef, userData, { merge: true });
      console.log("User data saved to Firestore.");
    } catch (error) {
      console.error("Error saving user data to Firestore:", error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");

    if (code) {
      getKakaoToken(code);
    } else {
      console.error("Authorization code missing.");
      navigate("/");
    }
  }, []);

  return <div>Processing Kakao login...</div>;
};

export default Kakaocallback;
