import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { db } from "../../firebase";
import Swal from "sweetalert2";

const MyPageMenu = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(
    () => JSON.parse(localStorage.getItem("userInfo")) || null
  );

  useEffect(() => {
    const fetchUserInfo = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        // Fetch user info from Firestore collection
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserInfo(userData); // Set state with Firestore data
          localStorage.setItem("userInfo", JSON.stringify(userData)); // Save to localStorage
        }
      }
    };

    if (!userInfo) {
      fetchUserInfo(); // Fetch data if not already available
    }
  }, [userInfo]);

  const logOutConfirm = () => {
    Swal.fire({
      title: "정말 나가시겠어요?",
      showCancelButton: true,
      confirmButtonColor: "#55A349",
      cancelButtonColor: "#ddd",
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        const auth = getAuth();
        signOut(auth).then(() => {
          localStorage.removeItem("userInfo"); // Clear localStorage
          navigate("/login");
        });
      }
    });
  };

  return (
    <Wrap>
      <ContentWrap>
        <LabelProfile>
          <ProfileImg
            onClick={() => navigate("/mypage")}
            style={{ backgroundImage: `url(${userInfo?.profileImage})` }}
          />
        </LabelProfile>
        <SmallInfo>
          <UserNickname>{userInfo?.name || "Unknown"}</UserNickname>
          <UserEmail>{userInfo?.email || "No Email"}</UserEmail>
        </SmallInfo>
        <BottomWrap>
          <Label>
            <FormCheckText onClick={() => navigate("/mypage/editmemberinfo")}>
              회원정보 수정
            </FormCheckText>
          </Label>
          <Label>
            <FormCheckText onClick={logOutConfirm}>로그아웃</FormCheckText>
          </Label>
        </BottomWrap>
      </ContentWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  border: none;
  width: 85%;
  height: auto;
  background-color: #fff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 30px 20px 16px 20px;
  grid-column: 2 /3;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 760px) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    padding: 20px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
    width: 88%;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    padding: 20px;
  }
`;

const ContentWrap = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-content: center;
  @media only screen and (max-width: 760px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const ProfileImg = styled.img`
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 70%;
  align-self: center;
  @media only screen and (max-width: 760px) {
    width: 100px;
    height: 100px;
  }
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const SmallInfo = styled.div`
  color: #02113b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const UserNickname = styled.span`
  font-size: 18px;
  font-weight: 700;
  height: 25px;
  align-items: center;
  text-align: center;
  font-feature-settings: "tnum" on, "lnum" on;
  margin-top: 10px;
`;
const UserEmail = styled.span`
  font-size: 13px;
  margin: 0;
  align-items: center;
  text-align: center;
  font-feature-settings: "tnum" on, "lnum" on;
`;

const BottomWrap = styled.div`
  margin-top: 50px;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #02113b;
  display: flex;
  flex-direction: column;
  line-height: 35px;

  font-feature-settings: "tnum" on, "lnum" on;
  .EditInfo {
    cursor: pointer;
  }
  .ChangePw {
    margin-top: 6px;
    cursor: pointer;
  }
  .myContent {
    cursor: pointer;
  }
  .logOut {
    margin-top: 6px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 760px) {
    margin-top: 30px;
  }
`;

const FormCheckText = styled.span`
  width: auto;
  height: auto;
  font-weight: 400;
  font-size: 18px;
  background: transparent;
  border: none;

  cursor: pointer;
  &:hover {
    font-weight: 600;
  }
`;

const FormCheckLeft = styled.input.attrs({ type: "radio" })`
  &:checked {
    font-weight: 600;
  }
  &:checked + ${FormCheckText} {
    font-weight: 600;
  }
  display: none;
`;

const Label = styled.label`
  margin-bottom: 20px;
`;
const LabelProfile = styled.label`
  display: flex;
  flex-direction: center;
  justify-content: center;
`;

const FormCheckLeftProfile = styled.input.attrs({ type: "radio" })`
  display: none;
`;

export default MyPageMenu;
