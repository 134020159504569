import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase";

const MemberInfo = () => {
  const [userInfo, setUserInfo] = useState(
    () => JSON.parse(localStorage.getItem("userInfo")) || null
  );

  useEffect(() => {
    const fetchUserInfo = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        // Fetch user info from Firestore collection
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserInfo(userData); // Set state with Firestore data
          localStorage.setItem("userInfo", JSON.stringify(userData)); // Save to localStorage
        }
      }
    };

    if (!userInfo) {
      fetchUserInfo(); // Fetch data if not already available
    }
  }, [userInfo]);

  return (
    <Wrap>
      <Title>기본 정보</Title>
      <ContentWrap>
        <TopWrap>
          <ImgAndNames>
            <ProfileImg
              style={{ backgroundImage: `url(${userInfo?.profileImage || ""})` }}
            />
            <Names>
              <span className="userEmail">{userInfo?.name || "Unknown"}</span>
              <span className="userEmail">{userInfo?.email || "No Email"}</span>
            </Names>
          </ImgAndNames>
        </TopWrap>
        <Line />
        <BottomWrap>
          <AddressWrap>
            <SmallTitle>주소</SmallTitle>
            <span>{userInfo?.address || "주소를 등록해주세요"}</span>
          </AddressWrap>
          <CropsWrap>
            <SmallTitleCrops>내 작물</SmallTitleCrops>
            <div>
              {userInfo?.crops?.length
                ? userInfo.crops.map((crop, idx) => (
                    <span key={idx}>{crop}</span>
                  ))
                : "작물을 등록해주세요"}
            </div>
          </CropsWrap>
        </BottomWrap>
      </ContentWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  border: none;
  width: 92%;
  height: auto;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  background: #ffffff;
  padding: 40px 60px 40px 20px;
  grid-column: 3 / 6;
  @media only screen and (max-width: 1550px) {
    margin-left: 10px;
  }
  @media only screen and (max-width: 760px) {
    width: 90%;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    margin-left: -20px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
    width: 100%;
    padding: 20px;
    grid-column: 2 / 3;
    grid-row: 3 / 5;
  }
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  margin-left: 20px;
`;

const ContentWrap = styled.div``;

const TopWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const ProfileImg = styled.img`
  width: 110px;
  height: 110px;
  display: flex;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 70%;
  margin-left: 20px;
`;

const ImgAndNames = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UploadImg = styled.div`
  display: flex;
  flex-direction: column;
`;

const Names = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: center;

  .userEmail {
    margin-left: 20px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

const EditNicknameWrap = styled.span`
  width: auto;
  margin-left: 17px;
  font-size: 18px;
  font-weight: 700;
  border: none;
`;

const Line = styled.hr`
  margin-top: 30px;
  margin-bottom: 30px;
  width: 95%;
  border: solid 0.5px #d8d8d8;
  margin-left: 20px;
  padding-right: 40px;
  @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
    width: 75%;
  }
`;

const BottomWrap = styled.div`
  margin-left: 20px;
`;

const AddressWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 664px;
  @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
    width: 100%;
  }
`;

const TitleAndAddress = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
`;

const SmallTitle = styled.span`
  /* padding-right: 24px; */
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: #02113b;
  font-weight: 700;
  text-align: left;
  flex-wrap: wrap;
  @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
    /* padding-right: 15px; */
    width: 60px;
  }
`;
const PrevAddressWrap = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin-left: 60px;
  border: none;
  background-color: transparent;
  color: #02113b;
  text-align: left;
  flex-wrap: wrap;

  @media only screen and (max-width: 760px) {
    font-size: ${({ theme }) => theme.fontSizes.md};
    max-width: 300px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    margin-left: 30px;
    width: 200px;
  }
`;
const PrevAddress = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin-left: 0px;
  border: none;
  background-color: transparent;
  color: #02113b;
  text-align: left;
  flex-wrap: wrap;

  @media only screen and (max-width: 760px) {
    font-size: ${({ theme }) => theme.fontSizes.md};
    max-width: 300px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

const NoAddressMsg = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.md};
  border: none;
  background-color: transparent;
  color: #02113b;
  text-align: left;
  flex-wrap: wrap;

  @media only screen and (max-width: 760px) {
    font-size: ${({ theme }) => theme.fontSizes.md};
    margin-left: 24px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    max-width: 100px;
    margin-left: 5px;
  }
`;

const CropsWrap = styled.div`
  width: 664px;
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  @media only screen and (max-width: 760px) {
    margin-right: 0px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
    width: 100%;
    margin-right: 0px;
  }
`;

const TitleAndCrops = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SmallTitleCrops = styled.span`
  /* padding-right: 19px; */
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: #02113b;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  width: 60px;
`;

const PreviousMyCrops = styled.div`
  width: 550px;
  text-align: left;
  flex-wrap: wrap;
  .noCropTitle {
    font-size: ${({ theme }) => theme.fontSizes.md};
    flex-wrap: wrap;
    @media only screen and (max-width: 760px) {
      font-size: ${({ theme }) => theme.fontSizes.md};
    }
    @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
      font-size: ${({ theme }) => theme.fontSizes.sm};
    }
  }
  @media only screen and (max-width: 760px) {
    width: 200px;
  }
`;

const PreviousCropsList = styled.div`
  width: auto;
  height: auto;
  display: inline-block;
  flex-wrap: wrap;
  border: 1px solid #bfbfbf;
  padding: 6px 10px;
  color: #616161;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: 6px;
  /* flex-wrap: wrap; */
  @media only screen and (max-width: 760px) {
    margin-bottom: 6px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
    margin-bottom: 6px;
    font-size: 13px;
  }
`;

const CropsContent = styled.div`
  margin-left: 30px;
`;

const AreaWrap = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TitleAndArea = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

const SmallTitleArea = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: #02113b;
  font-weight: 700;
  line-height: 40px;
  @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
    line-height: 10px;
  }
`;

const Area = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin-left: 30px;
  @media only screen and (max-width: 760px) {
    margin-left: 30px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

export const SubmitBtn = styled.button`
  margin-top: 40px;
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: white;
  background-color: ${({ theme }) => theme.colors.mainColor};
  border: 1px solid ${({ theme }) => theme.colors.mainColor};
  padding: 6px 14px;
  border-radius: 8px;

  cursor: pointer;
  &:hover {
    background: #22631c;
    border: 1px solid #22631c;
  }
`;

export default MemberInfo;
