// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCotBXd2y0aM0C3qsKnPB4nX9BcIoiNgtY",
  authDomain: "link-94dc4.firebaseapp.com",
  projectId: "link-94dc4",
  storageBucket: "link-94dc4.firebasestorage.app",
  messagingSenderId: "1011607591672",
  appId: "1:1011607591672:web:8c4778f1321baa01e8390d",
  measurementId: "G-C53TG38YH8",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

// Set persistent authentication
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Authentication persistence set to local.");
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

  const storage = getStorage(firebaseApp);

export { firebaseApp, auth, db, storage };

