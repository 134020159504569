import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate, Routes, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; // Firebase Firestore import
import Header from "../components/Header";
import MyPageMenu from "../components/myPage/MyPageMenu";
import MemberInfo from "../components/myPage/MemberInfo";
import EditMemberInfo from "../components/myPage/EditMemberInfo";
import EditPw from "../components/myPage/EditPw";
import Footer from "../components/Footer";

// Images
import QuestionMark from "../images/QuestionMark.png";
import ExclamationMark from "../images/ExclamationMark.png";

const MyPage = () => {
  const navigate = useNavigate();
  const modalCloseRef = useRef();
  const [isHovering, setIsHovering] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await fetchUserInfo(user.uid);
      } else {
        navigate("/login"); // Redirect to login if not authenticated
      }
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, [navigate]);

  const fetchUserInfo = async (uid) => {
    try {
      const userDoc = doc(db, "users", uid); // Assuming 'users' collection exists
      const userSnap = await getDoc(userDoc);

      if (userSnap.exists()) {
        setUserInfo(userSnap.data());
      } else {
        console.error("No user data found!");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  return (
    <>
      <Wrap>
        <Header />
        <MyPageMenu />
        <Routes>
          <Route path="/" element={<MemberInfo userInfo={userInfo} />} />
          <Route path="/editmemberinfo" element={<EditMemberInfo userInfo={userInfo} />} />
        </Routes>
      </Wrap>
      <Footer currentpage="schedule" />
    </>
  );
};

// Styled components
const boxFadeB = keyframes`
  0% {
  opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const boxFadeC = keyframes`
  0% {
    transform: scale(1, 1);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: scale(1.2, 1.2);
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.15);
  }
`;

const Wrap = styled.div`
  height: 80vh;
  margin-top: 100px;
  margin-bottom: 50px;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr repeat(4, 18.75%) 1fr;
  justify-content: center;
  row-gap: 16px;

  @media only screen and (max-width: 1220px) {
    grid-template-columns: 1fr repeat(3, 25%) 1fr;
  }
  @media only screen and (max-width: ${({ theme }) => theme.device.tablet}) {
    margin-top: 90px;
    grid-template-columns: 1fr 95% 1fr;
  }
  @media only screen and (max-width: ${({ theme }) => theme.device.mobile}) {
    margin-top: 90px;
    row-gap: 16px;
    grid-template-columns: 30px 78% 30px;
  }
`;

const Info = styled.div`
  width: 220px;
  height: 60px;
  border-radius: 8px;
  position: fixed;
  right: 190px;
  bottom: 100px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: ${boxFadeB} 1s;
  z-index: 1000;

  @media only screen and (max-width: ${({ theme }) => theme.device.tablet}) {
    bottom: 120px;
    right: 150px;
  }
`;

const Icon = styled.div`
  width: 80px;
  height: 80px;
  background-image: url(${(props) => props.Image});
  background-position: center 30%;
  background-size: cover;
  position: fixed;
  bottom: 90px;
  right: 70px;
  z-index: 110;
  border-radius: 100px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);

  cursor: pointer;
  &:hover {
    animation: ${boxFadeC} 2s;
    background-image: url(${(props) => props.chickenIcon});
  }

  @media only screen and (max-width: ${({ theme }) => theme.device.tablet}) {
    display: none;
  }
`;

const Emoji = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  margin-right: 4px;
`;

export default MyPage;
