import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../firebase"; // Import Firebase services
import Header from "../components/Header";
import Weather from "../components/main/Weather";
import MarketPriceCard from "../components/main/TodayMarketPriceCard";
import TodayTodo from "../components/main/TodayTodo";
import Analysis from "../pages/Analysis";
import TodayNews from "../components/main/TodayNews";
import FooterNav from "../components/FooterNav";
import Footer from "../components/Footer";
import QuestionMark from "../images/QuestionMark.png";
import ExclamationMark from "../images/ExclamationMark.png";

const Main = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [analysisData, setAnalysisData] = useState({
    income: null,
    expense: null,
    worktime: null,
    sales: null,
    totalHarvest: null,
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/login");
      } else {
        fetchUserData(user.uid);
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const fetchUserData = async (userId) => {
    try {
      const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserData(docSnap.data());
        fetchAnalysisData();
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchAnalysisData = async () => {
    try {
      const categories = ["income", "expense", "worktime", "sales", "totalHarvest"];
      const dataPromises = categories.map((category) =>
        getDoc(doc(db, "analysis", category))
      );
      const [income, expense, worktime, sales, totalHarvest] = await Promise.all(dataPromises);

      setAnalysisData({
        income: income.data(),
        expense: expense.data(),
        worktime: worktime.data(),
        sales: sales.data(),
        totalHarvest: totalHarvest.data(),
      });
    } catch (error) {
      console.error("Error fetching analysis data:", error);
    }
  };

  return (
    <Wrap>
      <Header currentPage="main" />
      <Weather />
      {/* <MarketPriceCard /> */}
      {/* <TodayTodo />  */}
      {/* <Analysis /> */}
       <TodayNews />
      <Footer />
      <FooterNav currentPage="main" />
    </Wrap>
  );
};

const boxFade = keyframes`
  0% {
    transform: scale(1, 1);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);

  }
  100% {
    transform: scale(1.2, 1.2);
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.15);
  }
`;

const boxFadeB = keyframes`
  0% {
  opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Wrap = styled.div`
  width: 100vw;
  max-width: 1920px;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr repeat(3, 25%) 1fr;
  grid-template-rows: 80px repeat(6, 110px);
  justify-content: center;
  flex-flow: wrap;
  row-gap: 16px;
  column-gap: 20px;
  @media only screen and (max-width: 1220px) {
    grid-template-columns: 1fr repeat(3, 27.5%) 1fr;
  }
  @media only screen and (max-width: ${({ theme }) => theme.device.tablet}) {
    grid-template-columns: 1fr 90% 1fr;
    grid-template-rows: 80px repeat(12, auto) 50px;
  }
`;

const Info = styled.div`
  width: 220px;
  height: 60px;
  border-radius: 8px;
  position: absolute;
  position: fixed;
  right: 190px;
  bottom: 100px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: ${boxFadeB} 1s;
  z-index: 1000;
  @media only screen and (max-width: ${({ theme }) => theme.device.tablet}) {
    bottom: 120px;
    right: 150px;
  }
`;

const Icon = styled.div`
  width: 80px;
  height: 80px;
  background-image: url(${(props) => props.Image});
  background-position: center 30%;
  background-size: cover;
  position: fixed;
  bottom: 90px;
  right: 70px;
  z-index: 110;
  border-radius: 100px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);

  cursor: pointer;
  &:hover {
    animation: ${boxFade} 2s;
    background-image: url(${(props) => props.chickenIcon});
  }
  @media only screen and (max-width: ${({ theme }) => theme.device.tablet}) {
    display: none;
  }
`;

const Emoji = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  margin-right: 4px;
`;

export default Main;
